/* Global body and html styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%; /* Ensure body does not exceed 100% width */
  max-width: 100%; /* Prevent any content from exceeding viewport width */
}

/* Ensures padding and borders are included in width and height */
* {
  box-sizing: border-box; /* Important for preventing overflow */
  margin: 0; /* Reset default margin */
  padding: 0; /* Reset default padding */
}

/* Prevent overflow caused by large content */
.container {
  max-width: 100%;
  overflow: hidden; /* Prevents content from causing horizontal scrolling */
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;